import React from "react";
import "../about.css";
function Cities(props) {
  return (
    <div className="about">
        <div className="w3-col">
            <img src="../img/FlorenceCover.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%' ,justifyContent: 'center'}} />
          </div>
          <div className="w3-container w3-padding-64" id="contact">
          <h5 className="w3-center">Top 10 Towns/Cities To Visit</h5><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>Below is a list of some of the best towns/cities which I have visited and which I think are worthy of recommendation. Some of these towns/cities are great for a weekend or a short term trip and some of them may need a longer trip to get there but ultimately they are all worth the visit regardless.</p>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Uluwatu.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">10. Uluwatu, Bali, Indonesia</h5><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">9. Budapest, Hungary</h4><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Budapest.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Dubrovnik.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">8. Dubrovnik, Croatia</h5><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">7. Chicago, USA</h4><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Chicago.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Barcelona.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">6. Barcelona, Spain</h5><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">5. Seminyak, Bali, Indonesia</h4><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Seminyak.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Ubud.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">4. Ubud, Bali, Indonesia</h5><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">3. Siargao, Philippines</h4><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/naked-island-siargao-philippines.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/ChiangMai.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">2. Chiang Mai, Thailand</h5><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">1. Florence, Italy</h4><br />
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Florence.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
      </div>
  );
}

export default Cities;
