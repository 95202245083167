import React from "react";
import "../about.css";
function Countries(props) {
  return (
    <div className="about">
        <div className="w3-col">
            <img src="../img/TopTenCountries.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%' ,justifyContent: 'center'}} />
          </div>
          <div className="w3-container w3-padding-64" id="contact">
          <h5 className="w3-center">Top 10 Countries To Visit</h5><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>We all want to travel to different countries and experience different cultures at some point in our lives
          but it's not always easy to decide which countries to go to or even which countries you should visit first. After all, there are 195 countries in the world, each with their
          own different cultures and practices which together make the decision even more difficult. To help with this I've put together a list of the 10 best countries that I've
          visited myself and provided a few reasons as to why I chose them and what makes them worthy of a visit. Hopefully, this can help you when deciding your next holiday destination.</p>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Bosnia.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">10. Bosnia</h5><br />
            <p className="w3-large">The country of Bosnia kicks off the list at number 10. With a history which has been consumed by war up until 1995, not many people would consider a visit to Bosnia
            but once you see what the country has to offer and especially how cheap it is there you might think again. Bosnia has the 8th lowest cost of living in Europe so when it comes to food and
            drinks as well as accommodation you won't have to spend too much money to get whatever you like. Bosnia also borders Croatia and so day trips are very much possible if you aren't interested
            in planning a whole trip to the country.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">
            In the town of Mostar (pictured) you can watch divers jump (or jump yourself if you're feeling brave) from the 29 meter high Stari Most bridge into the Neretva River. The bridge was
            originally built in the 16th but was destroyed during the war before being rebuilt and then reopened again in 2004. Mostar is a lovely little town and can be visited as part of a day trip
            from Dubrovnik in Croatia. Also near the border of Croatia, you have the Kravice Waterfalls which are sometimes known as the "mini Niagara Falls" because of their similar appearance. Other
            noteworthy places to visit include the capital Sarajevo, Tara Canyon and the Dervish Monastery in Blagaj.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">9. Slovenia</h4><br />
            <p className="w3-large">Slovenia is without a doubt one of the most amazing countries in Europe and even though it is a country known for being a nation of underground caves, the scenery it has above ground
            provides enough justification to visit it. Located in the heart of Europe, Slovenia is home to the most visited cave on the continent and has some of the most amazing scenery that you're ever likely to see.
            The country has a below-average cost of living compared to the rest of Europe and so although it might not be as cheap as Bosnia, you still will not have to break the bank to visit.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Lake Bled (pictured) is probably the main destination in Slovenia and it is easy to see why when you see the view of a church resting on an island
            in the middle of a lake surrounded by mountains. Along with the amazing views, Bled also has plenty of activities to offer such as Tobogganing, bike and boat rental and kayaking to name a few. Just 5km from Lake Bled
            you will find Vintar Gorge which is where you can follow a boardwalk over the crystal clear water all the way through the gorge. If you're interested in seeing the Alps then there is no better way to do so than hiking
            in Triglav National Park where you can get some unbelievable views of the surrounding mountains. Other places worth visiting include the capital Ljubljana (good luck pronouncing that), the largest cave castle in the world
            Predjama Castle and one of the largest underground canyons in the world - The Skocjan Caves.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Slovenia.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Austria.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">8. Austria</h5><br />
            <p className="w3-large">Another place known for its amazing mountains and landscapes is Austria. Bordering Slovenia in the centre of Europe, Austria also features some unbelievable views of the alps and is a very popular
            location for skiing in the winter months. Austria also has some of the best architecture in Europe and you will never be too far away from a castle or a palace wherever you travel to in the country. The only downside about
            travelling to Austria is that it is not as cheap as the countries previously mentioned and it actually ranks as one of the more expensive countries in Europe when it comes to cost of living. If you're not looking for a super
            budget-friendly trip then I would definitely add Austria to your list.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">One of the must-see places in Austria is the small town of Hallstatt (pictured). A UNESCO world heritage site since 1997, Hallstatt is one of the most picturesque towns in Europe
            and has some unbelievable views of the surrounding mountains especially from above on the Skydeck viewing point. The town is also home to one of the oldest salt mines in the world and when you visit you can even dress up and explore
            like the original miners once did. You can choose to either stay in the town itself which is pretty expensive, stay on the outskirts of the town in one of the smaller villages nearby or even do a day trip from Salzburg which is about
            2 hours away by train. The cities of Salzburg and Vienna are also worth seeing and have some amazing castles and palaces such as the Hofburg palace in Vienna and St. Peter's Abbey in Salzburg. Other places outside of the cities which
            are worth a visit are Krimmler Ache, Eisriesenwelt and the Grossglockner Road.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">7. Croatia</h4><br />
            <p className="w3-large">If you are looking for exotic weather and amazing islands or if you prefer ancient towns with huge castle walls and an interesting history then Croatia is the place for you. Located in the southeast of Europe
            along the Adriatic sea, Croatia has average temperatures in the high 20s during the summer and combining this with some of the great islands and beaches that it has makes for a great trip. In terms of costs, Croatia ranks in the middle
            when it comes to cost of living compared to the rest of Europe making it a great affordable place for backpackers as you won't have to break the bank to travel here.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The most famous and well-known place in Croatia is without a doubt the city of Dubrovnik with its huge city walls made famous from the Game Of Thrones TV show. The first thing you
            should do here is to take a walk around the ancient walls of the city and explore all that it has to offer. You can also do walking tours of the city if you want to learn a bit more about the history. Another great thing to do is to take a
            cable car to the top of Mount Srd (pictured) where you can get some amazing 360 views of the city and the surrounding area. Other cities with some amazing architecture similar to Dubrovnik include Rovinj, Pula, Split and Zadar. If you're
            looking for more of a party scene then the island of Hvar is a must-visit place. It is basically a nightclub that takes up a whole island and the only way to get there is by boat. Other noteworthy destinations to visit include the famous
            Zlatni Rat beach and the Plitvice Lakes.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Croatia.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Switzerland.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">6. Switzerland</h5><br />
            <p className="w3-large">At number six on the list, we have Switzerland, one of the most scenic countries in the world and a country known for many inventions including the Swiss Army Knife and of course...milk chocolate. Switzerland is
            located in central Europe and the Alps cover almost half of the country itself making for some really amazing hikes and adventures. The only downside that Switzerland has is that it is one of the most expensive countries to visit in all of Europe
            and has the highest cost of living by a wide margin. If you are looking for a budget trip then Switzerland might not be the right choice for you but if budget is not an issue and you're looking for an adventure then I would definitely recommend it.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">One of my favourite places in Switzerland would have to be the town of Zermatt in the Swiss/Italian Alps. This town and its surroundings are just like something out of a movie with the famous
            Toblerone Mountain the Matterhorn (pictured) towering over the village below. There is so much you can do in Zermatt with the main attractions being skiing during the winter months and hiking during the summer months. The Gornergrat Bahn which is a
            train that takes you to the top of the Gornergrat mountain is a must-do as it is the highest open-air railway in Europe and offers some unbelievable views of the Matterhorn. Other must-see places in Switzerland include the resort of Interlaken
            where you can find almost any adventure you could imagine, the famous Rhine Falls and also the village of Appenzell. The cities of Bern and Zurich are also worth mentioning.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">5. Italy</h4><br />
            <p className="w3-large">At number five we have Italy, my absolute favourite country in Europe and a country which has a bit of everything when it comes to travel. Italy has an amazing history from the buildings of ancient Rome to the ruins of Pompeii.
            It also has some of the most amazing mountain scenery with the Italian Alps and the Dolomites in the north and some of the most breathtaking coastal towns along the Amalfi Coast. As far as costs go Italy stands about above average in cost of living
            meaning that trips to this south European country may not necessarily be cheap but there are absolutely still affordable.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">There is so much to see in Italy that it is hard to know where to start but in my view, Florence and Rome are two must-see places and would be first on my list. Florence is a living example of Renaissance
            architecture with its white buildings and red-ish/orange rooftops and you could easily just walk the streets and admire your surroundings for days. One thing you must do while in Florence is climb to the top of the Duomo cathedral which offers great views of the whole
            city. Rome, of course, has most of the remaining ruins of the Roman empire with the Colosseum being the main attraction in the city and a must-visit place that is well worth the queues to get in. Rome always has the ancient Forum, the Trevi fountain and the Vatican as
            other must-see places. The Italian coastline is also an amazing sight and the Amalfi Coast has to be on your list if you visit. The weather here is nearly always perfect for a sun holiday and once you see these amazing coastal towns popping out of the sea on the side
            of mountains such as Positano, you will never want to leave.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/ItalyCountry.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/ThailandCountry.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">4. Thailand</h5><br />
            <p className="w3-large">The first non-European country and number four on the list is the great country of Thailand. Thailand is located in Southeast Asia and is famous for its tropical beaches, huge Buddhist temples and amazing palaces. The countries cultures and
            practices are very different to that of western countries and if it is your first time in Asia it may take a while to get used to it but if you embrace it and get to know about the country and its history then it will make your experience that much better. Thailand is
            normally one of the cheapest Asian countries to fly to from Europe and in terms of cost of living the country would be very similar to Croatia which means trips here are very much affordable.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The city of Bangkok is the main travel hub in Thailand and has many temples and palaces to explore along with a wide variety of food to try out and some of the biggest shopping markets in the world. For backpackers Khao San
            Road is the place to be as it has pretty much everything you'd need from shops and street food to hostels and nightlife and is a great place to meet other travellers. Bangkok also has some amazing temples and palaces such as the Grand Palace, Wat Pho and Wat Arun. To the north
            of Thailand, you have Chiang Mai and probably my favourite part of Thailand as it is where you will find most of the elephant sanctuaries who rescue captive elephants and allow you to spend the day looking after them in what is a truly amazing experience. Some other highlights
            of Chiang Mai include the Wat Phra Singh and Wat Chedi Luang temples. Thailand has much more to offer but to highlight a few more places worth considering there are the islands in the south Ko Tao, Ko Samui and Ko Pha Ngan as well as Khao Sok national park in the west.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">3. Iceland</h4><br />
            <p className="w3-large">Coming in at third on the list is Iceland. This is one country that I have travelled to where I actually thought I was on a different planet because the landscapes were so unique and out of this world. There are not many countries you can go to where you
            can actually say wherever you go you will be in awe of your surroundings but this is definitely true for Iceland with its wide range of mountains, waterfalls, volcanos and hot springs. However, like Switzerland, a trip to Iceland is definitely not on the cheap side as Iceland has
            one of the highest costs of living in the world. If you are on a budget the cheapest way of travelling around Iceland would be to rent a campervan and buy groceries for cooking your own meals as accommodation and restaurant prices are very high.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The Golden Circle is probably the most popular day trip in Iceland and consists of three separate stops which are Thingvellir National Park, Geysir and Gullfoss. Thingvellir is the only place in the world where the mid-Atlantic
            ridge can be seen above sea-level. Geysir is where you can find volcanic steaming vents shoot water out of the ground 40m into the air. Gullfoss is one of the most powerful and breathtaking waterfalls in Iceland. Some other waterfalls which are absolutely a must-visit while in Iceland
            are Seljalandsfoss, Skøgafoss(pictured), Svartifoss, Hengifoss and Haifoss. Ice Caves are a must-do adventure while you are in Iceland and the Local Guide company offer the best tours of the Vatnajokull ice caves. If you're looking to see huge glaciers up close then you have to visit
            Svinasfellsjokul and Fjallsarlon. Some other highlights that I would recommend would be blue lagoon, diamond beach, Stokksnes, Dyrholaey and Jokulsarlon glacier lagoon.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/IcelandCountry.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/whale-sharks-oslob-cebu-philippines.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">2. Philippines</h5><br />
            <p className="w3-large">In at number two on the list is the island nation of the Philippines. The Philippines is a country that you could spend months travelling around and you would never get bored or run out of places to see. This is good if you do have months to spend travelling
            around but if you only have a few weeks to spend then it can be difficult to try and fit in everything that you'd like to see. It is in the lower tier of countries in terms of cost of living so money will not be an issue when you travel here as accommodation, food and drink are very cheap
            and the most expensive part of your trip will be boat tours and travelling between islands.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Palawan is probably the first place that should be on any list when choosing where to visit in the Philippines. El Nido and Coron are the two most popular places in Palawan and for good reason. The best way to visit both of them is to
            spend a few days in one of them and then take a 2/3 day boat trip to the other, stopping off at some amazing little islands to camp along the way. Another great place to visit is Siargao over in the east of the Philippines which has a Bali-type feel to it with it's chilled out surf vibes and
            some great hostels and nightlife. If you're looking to see whale sharks(pictured) then you will have to travel to the town of Oslob in Cebu where they are most commonly found. There isn't a whole lot to do in Cebu other than canyoneering and the sardine run so I wouldn't spend too much time there.
            Some of the other islands to consider visiting include Boracay, Bohol, Siquijor and Puerto Princessa.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">1. Indonesia</h4><br />
            <p className="w3-large">Finally, at number one on the list, we have my favourite country to travel to and one of the most unique countries in the world, Indonesia. Much like the Philippines, Indonesia is a country made up of many different islands, each with their own equally amazing landscapes
            and cultures to explore. Indonesia is known for being a paradise destination and for good reason as it has many tropical islands with some of the best beaches in the world and it is easy to see just why it is so popular. Indonesia has a cost of living almost exactly the same as the Philippines
            and so smaller amounts of money can go a long way over there and it is affordable even to stay for long periods of time.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The most well known and well-travelled island in Indonesia is Bali and is a great starting point when you are trying to plan a holiday here. Ubud, Uluwatu, Seminyak and Caangu are the main places to base yourself and from there you can explore
            the island as you'd like. The islands nearby to Bali are almost as good if not better than Bali itself and a lot fewer people travel there. The Nusa islands to the east, especially Nusa Penida, are highly recommended. The Gili islands are a bit more popular but are still worth travelling to for a
            few days. The island of Lombok is very much like Bali was before tourism took over and it is an incredible place with lots to explore. To the west, you have Java which is another incredible island with the highlights being Mount Bromo(pictured), Ijen Blue Fire Lake the temples in Yogyakarta. Further to the
            east of Indonesia, you'll find the Komodo islands. This place is absolutely incredible and has a real untouched feeling to it. These islands are home to the largest lizard on the planet, the Komodo dragon, and is the only place where you can see these lizards in their natural habitat. Aside
            from the dragons, you can take a boat tour to the surrounding islands where you'll see some incredible landscapes with the highlight being Padar island.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/IndonesiaCountry.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
      </div>
  );
}

export default Countries;
