import React, { Component } from "react";
import Foot from "./Foot/Foot";
import { Grid, Row, Col } from "react-flexbox-grid";
import Paragraph from "./Paragraph/Paragraph";
import Section from "./Section/Section";
import Icon from "./Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import Title from "./Title/Title";
import Link from "./Link/Link";
import Border from './Border/Border';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <Foot>
          <Section paddingTop='40px' paddingBottom='40px' backColor="#313131">
            <Grid>
              <Row center="xs">
                <Col xs={12} lg={8}>
                  <Row center="xs">
                    <Col xs={8}>
                      <Col xs={12}>
                        <Title fontSize='2.5rem'color="#ffb637">Or catch me on social media...</Title>
                      </Col>
                      <Col xs={12}>
                        <Paragraph padding='30px 0' fsize=".8rem">
                         Feel free to also get in touch with me through any of the links below
                        </Paragraph>
                      </Col>
                    </Col>
                  </Row>
                  <Row center="xs">
                    <Col sm={6} xs={7}>
                      <Row center="xs" between="xs">
                        <Col>
                          <Link
                            target="_blank"
                            href="https://www.facebook.com/david.boothman.3"
                          >
                            <Icon>
                              <FontAwesomeIcon icon={faFacebookF} />
                            </Icon>
                          </Link>
                        </Col>
                        <Col>
                          <Link target="_blank" href="https://www.twitter.com/daveb95">
                            <Icon>
                              <FontAwesomeIcon icon={faTwitter} />
                            </Icon>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            target="_blank"
                            href="https://www.linkedin.com/in/david-boothman-937ab3111"
                          >
                            <Icon>
                              <FontAwesomeIcon icon={faLinkedin} />
                            </Icon>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            target="_blank"
                            href="https://www.github.com/DaveB95"
                          >
                            <Icon>
                              <FontAwesomeIcon icon={faGithub} />
                            </Icon>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            target="_blank"
                            href="https://www.instagram.com/david_boothman"
                          >
                            <Icon>
                              <FontAwesomeIcon icon={faInstagram} />
                            </Icon>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Border margin='40px 0' borderColor='rgba(70,70,70,1)' />
                </Col>
              </Row>
            </Grid>
          </Section>
        </Foot>
      </React.Fragment>
    );
  }
}

export default Footer;
