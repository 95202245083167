import React,{useState} from "react";
import Thumbnail from "./thumbnail.js";
import "../photo.css";
import FsLightbox from 'fslightbox-react'; 

const videos1=['https://youtu.be/UDKqLRupghc']
const videos2=['https://youtu.be/DGNUvRaDrfg']
const videos3=['https://youtu.be/NQBbYgKFxbI']
const videos4=['https://youtu.be/AQmIyJ2WBog']
const videos5=['https://www.youtube.com/watch?v=s-zDljQuWUU&t=6s']
const videos6=['https://www.youtube.com/watch?v=nDRouustDs0&t=12s']
const videos7=['https://www.youtube.com/watch?v=ZCT0krNeMnk&t=3s']
const videos8=['https://www.youtube.com/watch?v=fnQYpx89n_8&t=19s']
const videos9=['https://www.youtube.com/watch?v=WIttQFSy828']
const videos10=['https://www.youtube.com/watch?v=YLQf5NlSCfk&t=15s']
const videos11=['https://www.youtube.com/watch?v=x1mcwatGcSE']
const videos12=['https://www.youtube.com/watch?v=Nf_-Yl_c3bw']
const videos13=['https://www.youtube.com/watch?v=J6KBpg7bzPc']
const videos14=['https://www.youtube.com/watch?v=rQNPDfK4h8w']
const videos15=['https://www.youtube.com/watch?v=HR5cPlY1Vc4']
const videos16=['https://www.youtube.com/watch?v=2CPA7_TnLTQ']
const videos17=['https://www.youtube.com/watch?v=4F7qWfm0aIE']
const videos18=['https://www.youtube.com/watch?v=XEP__xKaNWo&t=22s']
const videos19=['https://www.youtube.com/watch?v=lGwmmlo29s4']
const videos20=['https://www.youtube.com/watch?v=D78PT_TvFmY']
const videos21=['https://www.youtube.com/watch?v=2U33roHNLo0']

const videos=[
  videos1,videos2,videos3,videos4,videos5,videos6,videos7,videos8,videos9,videos10,videos11,videos12,videos13,videos14,videos15,videos16,videos17,videos18,videos19,videos20,videos21
]
function Videos(props) {
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  return (
    // Render a Thumbnail component

    <div className="videos">
      <FsLightbox
        toggler={ toggler }
        sources={ videos[productIndex] }
        //maxYoutubeVideoDimensions={ { width: 1920, height: 1080 } }
        type="youtube"
        key={productIndex}
      />
      <Thumbnail
        link="https://youtu.be/UDKqLRupghc"
        image="../img/Thailand.jpg"
        title="Thailand"
        category="Shots from Chiang Mai and Bangkok in 2019"
        onClick={ () => {setProductIndex(0); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://youtu.be/DGNUvRaDrfg"
        image="../img/YiPeng.jpg"
        title="Yee Peng Festival"
        category="Footage from the Yee Peng Lantern Festival in Chiang Mai"
        onClick={ () => {setProductIndex(1); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://youtu.be/NQBbYgKFxbI"
        image="../img/Philippines.jpg"
        title="The Philippines"
        category="A trip to Coron, Siargao and Cebu in 2019"
        onClick={ () => {setProductIndex(2); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://youtu.be/AQmIyJ2WBog"
        image="../img/PhilippinesDrone.jpg"
        title="Philippines From The Sky"
        category="Some drone footage from around The Philippines"
        onClick={ () => {setProductIndex(3); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=s-zDljQuWUU&t=6s"
        image="../img/Interrail1.jpg"
        title="Interrail 1.0"
        category="Interrailing through Croatia, Bosnia, Montenegro, Slovenia, Austria, Germany and Hungary."
        onClick={ () => {setProductIndex(4); setToggler(!toggler);}}
     />

      <Thumbnail
        link="https://www.youtube.com/watch?v=nDRouustDs0&t=12s"
        image="../img/Interrail2.jpg"
        title="Interrail 2.0"
        category="Interrailing through Barcelona, Marseille, Nice, Genoa, Zermatt and Milan."
        onClick={ () => {setProductIndex(5); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=ZCT0krNeMnk&t=3s"
        image="../img/Indonesia.jpg"
        title="Indonesia 2018"
        category="Travelling around Indonesia"
        onClick={ () => {setProductIndex(6); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=fnQYpx89n_8&t=19s"
        image="../img/Iceland.jpg"
        title="Iceland"
        category="One week travelling around Iceland"
        onClick={ () => {setProductIndex(7); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=WIttQFSy828"
        image="../img/IcelandDrone.jpg"
        title="Flying Around Iceland"
        category="Some drone shots from the land of ice and fire, Iceland"
        onClick={ () => {setProductIndex(8); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=YLQf5NlSCfk&t=15s"
        image="../img/BRC.jpg"
        title="Blessington Rugby Club"
        category="Some footage from Blessington Rugby Club blitz"
        onClick={ () => {setProductIndex(9); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=x1mcwatGcSE"
        image="../img/LK.jpg"
        title="Lizzie Keogh's Bar"
        category="Promo video for Lizzie Keogh's bar in Baltinglass"
        onClick={ () => {setProductIndex(10); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=Nf_-Yl_c3bw"
        image="../img/ITC.jpg"
        title="Inspire The City"
        category="Inspire The City event held in Fresh, Capitol Dock"
        onClick={ () => {setProductIndex(11); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=J6KBpg7bzPc"
        image="../img/ElTemplo.jpg"
        title="El Templo MMA"
        category="Promo video for El Templo MMA"
        onClick={ () => {setProductIndex(12); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=rQNPDfK4h8w"
        image="../img/EP.jpg"
        title="Electric Picnic 2018"
        category="Some clips from Electric Picnic music festival 2018"
        onClick={ () => {setProductIndex(13); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=HR5cPlY1Vc4"
        image="../img/AIF.jpg"
        title="All In Fitness"
        category="Promo video for All In Fitness gym"
        onClick={ () => {setProductIndex(14); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=2CPA7_TnLTQ"
        image="../img/Gaa.jpg"
        title="Blessington Gaa Blitz"
        category="Leinster blitz held in Blessington Gaa Grounds"
        onClick={ () => {setProductIndex(15); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=4F7qWfm0aIE"
        image="../img/2018.jpg"
        title="My Year 2018"
        category="Some clips from 2018"
        onClick={ () => {setProductIndex(16); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=XEP__xKaNWo&t=22s"
        image="../img/Coumshingaun.jpg"
        title="Coumshingaun, Co.Waterford"
        category="Hiking Coumshingaun"
        onClick={ () => {setProductIndex(17); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=lGwmmlo29s4"
        image="../img/Donard.jpg"
        title="Donard Country Music Video"
        category="Highlight video of Donard Country Music Festival"
        onClick={ () => {setProductIndex(18); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=D78PT_TvFmY"
        image="../img/Promo.jpg"
        title="David Boothman Videography"
        category="What I can offer to you"
        onClick={ () => {setProductIndex(19); setToggler(!toggler);}}
      />

      <Thumbnail
        link="https://www.youtube.com/watch?v=2U33roHNLo0"
        image="../img/Cave.jpg"
        title="Diamond Ice Cave"
        category="What it's like to enter an ice cave"
        onClick={ () => {setProductIndex(20); setToggler(!toggler);}}
      />
    </div>
  );
}

export default Videos;
