import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Paragraph from "./Paragraph/Paragraph";
import Section from "./Section/Section";
import { Link as ReactLink } from "react-router-dom";
import Button from "./Button/Button";
import Title from "./Title/Title";
import Form from "./Form/Form";
import Input from "./Form/Input/Input";
import TextArea from './Form/Input/TextArea';
import axios from 'axios';

var status = 'Please input details in the form provided.';
var completed = 'Send';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  }
  render() {
    return (
      <React.Fragment>
        <Section>
          <Grid>
            <Row>
              <Col xs={11} lg={11}>
                <Row>
                  <Col xs={12} md={6}>
                    <Title color="#ffb637" largeFont>
                      Hello!
                    </Title>
                    <Paragraph>
                      { status }
                    </Paragraph>
                    <ReactLink to="/">
                      <Button
                        color="#ffb637"
                        bcolor="none"
                        hcolor="#fff"
                        hbcolor="#ffb637"
                        borderColor="#ffb637"
                        hborderColor="#ffb637"
                        margin="30px 0"
                        padding="10px 15px"
                        fsize=".75rem"
                      >
                        Return
                      </Button>
                    </ReactLink>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form>
                      <Row>
                        <Col xs={6}>
                          <Input placeholder="Name" required onChange={this.onNameChange.bind(this)}/>
                        </Col>
                        <Col xs={6}>
                          <Input placeholder="Email" type="email" required onChange={this.onEmailChange.bind(this)}/>
                        </Col>
                      </Row>
                      <Input placeholder="Subject" required onChange={this.onSubjectChange.bind(this)}/>
                      <TextArea largeText placeholder="Message" required onChange={this.onMessageChange.bind(this)}/>
                      <Input
                        pointer
                        color="#fff"
                        backColor="linear-gradient(to top, #1d37be 0%, #1b5cde 100%)"
                        noMargin
                        type="submit"
                        value={completed}
                        onClick={this.handleSubmit.bind(this)}
                      />
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Section>
      </React.Fragment>
    );
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
    }
  
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onSubjectChange(event) {
    this.setState({subject: event.target.value})
  }
  
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
  
  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST", 
      url:"https://sf4ampi501.execute-api.eu-west-1.amazonaws.com/01/contact-me", 
      data:  this.state
    }).then((response)=>{
        this.resetForm();
    })
  }

  resetForm(){
    this.setState({name: '', email: '', subject: '', message: ''});
    status = 'Message Sent! Click return to go back to the main page.';
    completed = 'Message Sent!'
 }
}

export default Contact;
