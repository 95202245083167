import React from "react";
import "../about.css";
function IcelandGuide(props) {
  return (
    <div className="about">
        <div className="w3-col">
            <img src="../img/icelandguide.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%' ,justifyContent: 'center'}} />
          </div>
          <div className="w3-container w3-padding-64" id="contact">
          <h5 className="w3-center">6 Day Iceland Itinerary</h5><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>In February 2019, myself and two friends flew to Iceland for a week-long trip around the land of ice and fire. Here I'll walk you through our itinerary and all of the places that we went to as well as talk about some of the highs and lows of the trip. I should also preface this by saying you can't really travel around Iceland properly unless you rent your own car/campervan or go on a fully guided tour so just keep that in mind when you're planning your trip. Another thing I should mention is that Iceland is like a different country in the winter than to the summer and in winter a lot of roads and destinations are inaccessible due to the snow. I think it is worth experiencing both winter and summer in Iceland which is why I plan to go back eventually and see it in the summertime.</p>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/iceland-guide-thingvellir-blue-lagoon-geysir-2.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 1: Arrive, Rent Car & Blue Lagoon</h5><br />
            <p className="w3-large">Once you touch down in Iceland you'll want to go and pick up your rental car straight away. As most of the locations in Iceland are pretty remote, a car is very much needed and in the winter months, a 4x4 is advisable. Shuttle buses regularly go from the terminal and stop off at the various rental car companies nearby. Once you've got your car you should then head straight to the Blue Lagoon which is about 20 minutes by car from the airport. If you choose not to rent a car then there is the option of a bus that will take you on the forty-minute journey to Blue Lagoon and afterwards bring you to Reykjavik which is another 40 minutes away.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Blue Lagoon is the main tourist destination in Iceland due to its famous blue water and for its name as one of the top spas in the world. Once you get there you'll pay an entrance fee or supply your pre-booking number and you'll be given a towel and a locker to put your stuff in. Then you're free to take a dip in the blue water and relax with mud masks and a complimentary drink as well as having the option to purchase more from the bar. When you're finished you should drive about an hour east to your accommodation in Selfoss which is a good place to kickstart your activities the next day.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 2: Golden Circle</h4><br />
            <p className="w3-large">The next day you will tour the famous 'Golden Circle' consisting of three stops which are Thingvellir National Park, Geysir and Gullfoss. Thingvellir is the only place in the world where the mid-Atlantic ridge can be seen above sea-level. Geysir is where you can find volcanic steaming vents shoot water out of the ground 40m into the air. Gullfoss is one of the most powerful and breathtaking waterfalls in Iceland. The Golden Circle also contains many locations which were used during the filming of the Game of Thrones tv show including Almannagjá gorge and the largest natural lake in Iceland, Þingvellirvatn.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">With an early start, you will be able to avoid the crowds of the tour groups coming from Reykjavik and get a chance to view these locations all by yourself. Most of the tours will probably head to Thingvellir first so you should start by driving to Geysir which is about a fifty-minute drive from Selfoss and then moving on to Gullfoss which is only a ten-minute drive away. Once you have seen the huge vents of Geysir, especially Strokkur, and have seen the power of the Gullfoss waterfall you can then make the one hour journey that will take you to Thingvellir National Park where you can go diving between two submerged rifts at Silfra and also see the incredible landscapes above ground. </p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/iceland-guide-thingvellir-blue-lagoon-geysir-1.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/seljalandsfoss-waterfall-iceland-3.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 3: Waterfalls & Dyrholaey</h5><br />
            <p className="w3-large">After another night in Selfoss, you will then begin to head east towards the next big town of Vik. On the way to Vik, there are a few must-see places that you will have to stop at and explore with the main two being the Seljalandsfoss and Skøgasfoss waterfalls. Seljalandsfoss is a 200ft waterfall and is unique due to the cliffs behind it which form a cavern that allows you to walk behind the waterfall for an amazing alternative view. Skøgasfoss is about the same height as Seljalandsfoss but much much wider making it one of the biggest waterfalls in Iceland and famous for the rainbows which are often created around it. Other places of note along the way include Seljavallalaug swimming pool, Solheimasandur plane wreck and Dyrholaey.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Seljalandsfoss is about an hour east from Selfoss and is clearly visible from the main road as you drive along. The nearby Gljufrabui waterfall is also worth visiting. Thirty minutes east from here you will find the Skøgasfoss waterfall which you will find on your left as you get closer to its location. You should walk up closer to the waterfall for a better view and there is also a hike along the right side of the waterfall for a view from the top. Another thirty minutes east from here and close to Vik you should drive to Dyrholaey where you will find an incredible sunset spot to end your day before heading onwards to Vik. Next to Vik, you will also find Reynisfjara Beach which is the most famous black sand beach in Iceland.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 4: Ice Cave, Diamond Beach & Stokksnes</h4><br />
            <p className="w3-large">Early the next morning you will get up, get some breakfast and drive two hours east towards the Jøkulsarlon Glacier Lagoon where you will meet up with your tour group for the Ice Cave tour. A good tour operator that I would recommend would be Local Guide who provide a great service and bring you to some of the best caves in Iceland. You will be transported to the caves in a specialised 4x4 super jeep which is capable of scaling the glaciers and is a cool experience. Once you get to the caves it will be like stepping into another world as you are surrounded by blue ice everywhere you look. Afterwards, you will be transported back to the glacier lagoon where you should grab some food before you move on.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">TThe glacier lagoon itself is an amazing sight and you should spend some time taking a look at the huge icebergs floating around there. Across the road from the lagoon is Diamond Beach where you will find hundreds of these blue icebergs washed up on the beach for you to get up close and admire. An hour east of here and just passed the town of Hofn you will find the Stokksnes mountains which are some of the most picturesque mountain landscapes that you will ever see. You have to pay a small fee at the shop nearby so that you can drive down to an outlet and park up for the best view of the surrounding area. Towards the end of the day, you should head back to your accommodation in either Hof or Hofn.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Hvannadalshnukur-ice-cave-5.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/svartifoss-waterfall-iceland-1.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 5: Fjallsarlon, Svínafellsjökull & Svartifoss</h5><br />
            <p className="w3-large">On your fifth day in Iceland, you will head to Fjallsarlon glacier lagoon which is about a thirty-minute drive east from Hof. This is a lesser-known glacier lagoon located nearby to the Jøkulsarlon glacier lagoon and generally has a lot fewer people visiting it so often you will find that you have most of the place to yourself. You will be able to see the huge icebergs floating in the lagoon and in the background you will also see the Vatnajøkull glacier which the icebergs have broken off from. During the warmer months, there are boat tours that can take you out on to the lagoon for a closer look at the icebergs. Next, you will drive about fifty minutes west to the Svínafellsjökull glacier which is also known as the 'Hollywood Glacier' due to it being chosen as a filming location for many movies and tv shows.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The Svínafellsjökull glacier is one of the best places in Iceland for getting up close to a glacier and allows you to see just how vast and impressive these glaciers are. As you are so close to the glacier you will also hear the occasional cracking sounds as the ice breaks and spreads apart. Nearby in Skaftafell, you will also find the Svartifoss waterfall which is famous for its hexagonal basalt columns that surround the waterfall. There is a hike that takes you passed several waterfalls before you reach Svartifoss and takes between two to three hours in total. Afterwards, you should begin to drive west as far as you can as the next day you will be heading towards Reykjavik.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 6: Öxarárfoss & Reykjavik</h4><br />
            <p className="w3-large">On your last day in Iceland, you will travel back west towards Reykjavik to spend some time exploring what the city has to offer. You can choose to either head straight to Reykjavik or you can take a detour north back to Thingvellir National Park which will only add another thirty minutes to your travel time. There is a lot more to see in Thingvellir including the Öxarárfoss waterfall which is located in the mid-Atlantic ridge. During the colder months, the waterfall is completely frozen over and is amazing to witness up close. To get to the waterfall you have to pass through the Almannagjá valley which allows you to experience walking between the North American and Eurasian continents.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Afterwards, you will head down towards Reykjavik where you should find a place to park your car while you explore the city. First, you should visit the Hallgrimskirkja Church which was inspired by the Svartifoss waterfall and is the most recognisable building in Iceland. The Harpa Concert Hall is an impressive glass building located down by the old harbour which is also worth seeing as you take a stroll around the streets. While in Reykjavik you have to try the national fast-food of Iceland which is the hot dog. It will be the cheapest meal you will have while in Iceland and definitely one of the nicest. Once you are finished exploring Reykjavik you can either stay the night here or find a place closer to the airport where you will drop your car back and fly home the next day.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/iceland-guide-thingvellir-blue-lagoon-geysir-3.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
      </div>
      
  );
}

export default IcelandGuide;
