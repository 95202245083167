import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Section from "./Section/Section";
import Section2 from "./Section/Section2";
import Title from "./Title/Title";
import TitleContainer from "./Title/TitleContainer";
import Paragraph from "./Paragraph/Paragraph";
import Image from "./Image/Image";
import Avatar from "../../images/Avatar.png";
import Portrait from "../../images/Portrait.jpg";
import Button from "./Button/Button";
import Test from "../../images/Test.png";
import BackgroundYellow from "../../images/BackgroundYellow.jpg";
import Pane from "./Pane/Pane";
import PaneOverlay from "./Pane/PaneOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode, faDatabase, faPeopleCarry, faServer } from "@fortawesome/free-solid-svg-icons";
import { Link as ReactLink } from "react-router-dom";
import Resume from "../../documents/david-boothman-cv.zip";
import Link from "./Link/Link";
import ITSuport from "../../images/ITSupport.jpg";
import AppDesign from "../../images/AppDesign.png";
import UXResearch from "../../images/UXResearch.jpg";


const AvatarStyle = {
  position: 'relative',
  top: '3px',
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: null,
      backColor:
        "linear-gradient(to right, transparent 0%, transparent 25%, transparent 60%, #ffb637 60%, #ffb637 100%)"
    };
  }
  render() {
    //  const Desktop =
    //     "linear-gradient(to right, transparent 0%, transparent 25%, transparent 60%, #ffb637 60%, #ffb637 100%)";
    //  const Mobile = "transparent";
    // const HeroColor = window.innerWidth >= 650 ? Desktop : Mobile;
    // Need to make event lisctenr for resize for responsive backgrouind

    return (
      <React.Fragment>
        <Section
          className="hero-color"
          paddingTop="20px"
          paddingBottom="0"
          backColor="#1A1B1C"
        >
          <Grid>
            <Row center="xs">
              <Col xs={11} lg={11}>
                <Row start="xs" middle="xs">
                  <Col lg={6} md={6} xs={12}>
                    <Paragraph color="#fff" fsize="1rem">
                      Hey
                    </Paragraph>
                    <Title color="#ffb637" largeFont>
                      I'm David
                    </Title>
                    <Paragraph color="#fff" lineHeight="2.5rem" fsize="2rem">
                      Software Engineer II at Lexis Nexis Risk Solutions
                    </Paragraph>
                    <ReactLink to="/contact">
                      <Button
                        color="#ffb637"
                        bcolor="none"
                        hcolor="#fff"
                        hbcolor="#ffb637"
                        borderColor="#ffb637"
                        hborderColor="#ffb637"
                        margin="20px 0"
                        padding="15px 50px"
                        fsize=".75rem"
                      >
                        CONTACT ME
                      </Button>
                    </ReactLink>
                  </Col>
                  <Col lg={6} md={6} xs={1}>
                    <Image style={AvatarStyle} sizex="100%" src={Avatar} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Section>

        <Section backColor="#313131">
          <Grid>
            <Row center="xs">
              <Col xs={11} lg={11}>
                <Row between="xs" start="xs" middle="xs">
                  <Col md={4} xs={1}>
                    <Image sizex="100%" src={Portrait} />
                  </Col>
                  <Col md={7} xs={12}>
                    <TitleContainer bcolor="#ffb637" margin="0">
                      <Title>About Me</Title>
                    </TitleContainer>
                    <Paragraph fsize=".8rem">
                      I graduated from Trinity College Dublin in 2018 with both an
                      Honors Bachelor degree and a Masters degree in Computer Science.
                      During my course I undertook a 6 month internship at Lexis Nexis Risk
                      Solutions where I began working with big data technologies and got my
                      first taste of the working world. Towards the end of my internship I
                      was offered the opportunity of coming back to the company as a full
                      time Software Engineer after I had finished my masters.
                    </Paragraph>
                    <Paragraph fsize=".8rem">
                      After completing my masters in Trinity in 2018 I went back to work
                      full time for Lexis Nexis where I continued to learn more about big
                      data technologies as well as full stack development and the agile
                      development process. I worked on various projects to improve, maintain
                      and implement both new and existing features for use in production
                      environments. Towards the end of 2019 I was promoted to the position
                      of Software Engineer II.
                    </Paragraph>
                    <Row>
                      <Col>
                        <ReactLink to="/contact">
                          <Button
                            color="#ffb637"
                            bcolor="none"
                            hcolor="#fff"
                            hbcolor="#ffb637"
                            borderColor="#ffb637"
                            hborderColor="#ffb637"
                            margin="20px 0"
                            padding="14px 30px"
                            fsize=".75rem"
                          >
                            CONTACT ME
                          </Button>
                        </ReactLink>
                      </Col>
                      <Col>
                        <Link href={Resume} download>
                          <Button
                            color="#ffb637"
                            bcolor="none"
                            hcolor="#fff"
                            hbcolor="#ffb637"
                            borderColor="#ffb637"
                            hborderColor="#ffb637"
                            margin="20px 10px"
                            padding="14px 30px"
                            fsize=".75rem"
                          >
                            DOWNLOAD CV
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Section>

        <Section 
        paddingBottom="60px"
        backColor="#1A1B1C">
          <Grid>
            <Row xs={11} lg={11} center="xs">
              <Col>
                <Row start="xs">
                  <Col md={6} xs={11}>
                    <TitleContainer bcolor="#ffb637" margin="0">
                      <Title>Skills</Title>
                    </TitleContainer>
                    <Paragraph padding="30px 0" fsize=".8rem">
                      Below are some but not all of the skills I have acquired over my years of working
                      in software developement and studying in college.
                    </Paragraph>
                  </Col>
                </Row>
                <Col>
                  <Row start="xs">
                    <Col md={6} xs={12}>
                      <Pane backImage={Test}>
                        <PaneOverlay backColor="linear-gradient(135deg, #37defe, rgba(255,0,0,0) 230%)">
                          <FontAwesomeIcon icon={faDatabase} size="2x" />
                          <Paragraph
                            padding="20px 0 0 0"
                            fsize="1.5rem"
                            color="#fff"
                          >
                            Big Data Engineering
                          </Paragraph>
                          <Paragraph fsize=".8rem" color="#fff">
                            I have multiple years of experience with a big data platform known as HPCC and Postgres.
                            I have recently began working on projects involving Elasticsearch, AWS and Grafana.
                          </Paragraph>
                        </PaneOverlay>
                      </Pane>
                    </Col>
                    <Col md={6} xs={12}>
                      <Pane backImage={ITSuport}>
                        <PaneOverlay backColor="linear-gradient(135deg, #fbd64c, rgba(255,0,0,0) 230%)">
                          <FontAwesomeIcon icon={faLaptopCode} size="2x" />
                          <Paragraph
                            padding="20px 0 0 0"
                            fsize="1.5rem"
                            color="#fff"
                          >
                            Front End Development
                          </Paragraph>
                          <Paragraph fsize=".8rem" color="#fff">
                            I have previous experience with javascript, html and css.
                          </Paragraph>
                        </PaneOverlay>
                      </Pane>
                    </Col>
                  </Row>
                  <Row start="xs">
                    <Col md={6} xs={12}>
                      <Pane backImage={UXResearch}>
                        <PaneOverlay backColor="linear-gradient(135deg, #f354b0, rgba(255,0,0,0) 230%)">
                          <FontAwesomeIcon icon={faServer} size="2x" />
                          <Paragraph
                            padding="20px 0 0 0"
                            fsize="1.5rem"
                            color="#fff"
                          >
                            Back End Development
                          </Paragraph>
                          <Paragraph
                            padding="20px 0 0 0"
                            fsize=".8rem"
                            color="#fff"
                          >
                            In terms of back end developement I have mainly been using Java with
                            the Spring application framework.
                          </Paragraph>
                        </PaneOverlay>
                      </Pane>
                    </Col>
                    <Col md={6} xs={12}>
                      <Pane backImage={AppDesign}>
                        <PaneOverlay backColor="linear-gradient(135deg, #2ec693, rgba(255,0,0,0) 230%)">
                          <FontAwesomeIcon icon={faPeopleCarry} size="2x" />
                          <Paragraph
                            padding="20px 0 0 0"
                            fsize="1.5rem"
                            color="#fff"
                          >
                            DevOps
                          </Paragraph>
                          <Paragraph fsize=".8rem" color="#fff">
                            I have previous experience using Jenkins to build and deploy
                            applications to several environments and more recently using
                            GitLab.
                          </Paragraph>
                        </PaneOverlay>
                      </Pane>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Grid>
        </Section>

        <Section2 backImage={BackgroundYellow}>
          <Grid>
            <Row center="xs">
              <Col xs={11} lg={11}>
                <Row center="xs">
                  <Col>
                    <TitleContainer bcolor="#fff" margin="auto">
                      <Title>Want to know more?</Title>
                    </TitleContainer>
                    <ReactLink to="/contact">
                      <Button
                        color="#000"
                        bcolor="#fff"
                        hcolor="#fff"
                        hbcolor="linear-gradient(to top, #1d37be 0%, #1b5cde 100%)"
                        borderColor="#fff"
                        hborderColor="#2F66A9"
                        margin="20px 0"
                        padding="15px 50px"
                        fsize=".75rem"
                      >
                        CONTACT ME
                      </Button>
                    </ReactLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Section2>
      </React.Fragment>
    );
  }
}

export default Home;
