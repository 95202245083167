import React from "react";
import "../about.css";

function NewComponent(props) {
  return (
    <div className="about">
    <div className="w3-content" style={{maxWidth: '1100px'}}>
        {/* About Section */}
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/TN.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">My Story</h5><br />
            <h5 className="w3-center">Filming since 2016</h5>
            <p className="w3-large">I began my filmmaking journey back in 2016 when I spent a whole summer in America on a J1 visa with a few friends and decided to record all of the adventures we had while we were there. When I got home I decided that any holidays or trips that I took in the future, I would record and document everything and make a video. This love for travel filmmaking took me all across Europe, up to the freezing cold of Iceland and over to tropical climates of South East Asia.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">From here things began to take off and I began working with Interrail Europe who offered to sponsor me to go Interrailing around Europe again. Then I began to work on projects closer to home with local sports clubs and businesses and anything I could get my hands on really. Fast forward to 2020 and I'm now working on projects on a weekly basis and am booked in to film multiple weddings in the coming months.</p>
          </div>
        </div>
        <hr />
        {/* Menu Section */}
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">What I Offer</h4><br />
            <h5>Promotional Videos</h5>
            <p className="w3-large w3-text-grey">I create real, authentic and vibrant videos to promote companies to the world.</p><br />
            <h5>Product Photography</h5>
            <p className="w3-large w3-text-grey">I create product shots for use in advertising, websites and social media.</p><br />
            <h5>Wedding Videos</h5>
            <p className="w3-large w3-text-grey">I capture memories from your big day so that you can have them forever.</p><br />
            <h5>Event Videos/Photos</h5>
            <p className="w3-large w3-text-grey">I create highlight reels from product launches to corporate fundraisers.</p><br />
            <h5>Drone Videos/Photos</h5>
            <p className="w3-large w3-text-grey">I create content from the sky to provide an alternative angle to what you see everyday.</p>    
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/About.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        {/* Contact Section */}
        <div className="w3-container w3-padding-64" id="contact">
          <h4 className="w3-center">Contact</h4><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>For queries or bookings you can contact me by phone 0863754462 or email davidboothman@gmail.com</p>
        </div>
        <hr />
        {/* End page content */}
      </div>
      </div>
  );
}

export default NewComponent;
