import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App />, 
    document.getElementById('root')
);

const left = document.querySelector(".left");
const right = document.querySelector(".right");
const container1 = document.querySelector(".container1");

if(left){
    left.addEventListener("mouseenter", () => {
        container1.classList.add("hover-left");
    });
      
    left.addEventListener("mouseleave", () => {
        container1.classList.remove("hover-left");
    });
}
if(right){
    right.addEventListener("mouseenter", () => {
        container1.classList.add("hover-right");
      });
      
    right.addEventListener("mouseleave", () => {
        container1.classList.remove("hover-right");
    });
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
