import React, { Component } from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import Head from "./Head/Head";
import { Grid, Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome} from "@fortawesome/free-solid-svg-icons";

const Menu = {
  color: '#fff',
  fontSize: '40px',
  fontWeight: '400',
}

class Header extends Component {
  render() {
    return (
      <BrowserRouter forceRefresh={true}>
      <React.Fragment>
        <Head>
          <Grid>
            <Row center="xs">
              <Col xs={6}>
              <NavLink to="/">
              <FontAwesomeIcon style={Menu} icon={faHome} />
              </NavLink>
              </Col>
            </Row>
          </Grid>
        </Head>
      </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default Header;
