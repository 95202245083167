import React, { useState } from "react"
import Thumbnail from "./thumbnail-photos";
import FsLightbox from 'fslightbox-react'; 
import "../photo.css";

const images1=[
  '../img/PhilippinesCover.jpg',
  '../img/CoronSunset.jpg',
  '../img/whale-sharks-oslob-cebu-philippines.jpg',
  '../img/naked-island-siargao-philippines.jpg',
  '../img/kayangan-lake-coron-philippines.jpg',
  '../img/banana-island-coron-palawan-philippines-1.jpg',
  '../img/banana-island-coron-palawan-philippines-2.jpg',
  '../img/ditaytayan-island-coron-palawan-philippines-1.jpg',
  '../img/ditaytayan-island-coron-palawan-philippines-2.jpg',
  '../img/ditaytayan-island-coron-palawan-philippines-3.jpg',
  '../img/Magpupungko-Rock-Pool-Siargao-Philippines-1.jpg',
  '../img/Magpupungko-Rock-Pool-Siargao-Philippines-2.jpg',
  '../img/Magpupungko-Rock-Pool-Siargao-Philippines-3.jpg',
  '../img/malcapuya-island-coron-palawan-philippines-1.jpg',
  '../img/Siargao-Island-Hopping-Tour-Day7.jpg',
  '../img/Concepcion-Falls-Coron-Palawan-Philippines-Day4.jpg',
  '../img/Cloud9-Siargao-Philippines-Day5.jpg',
  '../img/Coron-Island-Boat-Tour-Philippines-Day2.jpg',
  '../img/Coron-Island-Philippines-Day1.jpg',
  '../img/coconut-tree-philippines.jpg'
]
const images2=[
  '../img/ThailandCover.jpg',
  '../img/wat-samphran-dragon-temple-bangkok-thailand-1.jpg',
  '../img/wat-samphran-dragon-temple-bangkok-thailand-2.jpg',
  '../img/wat-samphran-dragon-temple-bangkok-thailand-3.jpg',
  '../img/wat-samphran-dragon-temple-bangkok-thailand-4.jpg',
  '../img/wat-samphran-dragon-temple-bangkok-thailand-5.jpg',
  '../img/elephant-chiang-mai-thailand-1.jpg',
  '../img/elephant-chiang-mai-thailand-2.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-1.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-2.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-3.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-4.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-5.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-6.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-7.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-8.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-9.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-10.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-11.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-12.jpg',
  '../img/yee-peng-loi-krathong-lantern-festival-chiang-mai-thailand-13.jpg'
]
const images3=[
  '../img/IcelandCover.jpg',
  '../img/Hvannadalshnukur-ice-cave-1.jpg',
  '../img/Hvannadalshnukur-ice-cave-2.jpg',
  '../img/Hvannadalshnukur-ice-cave-3.jpg',
  '../img/Hvannadalshnukur-ice-cave-4.jpg',
  '../img/Hvannadalshnukur-ice-cave-5.jpg',
  '../img/Hvannadalshnukur-ice-cave-6.jpg',
  '../img/Hvannadalshnukur-ice-cave-7.jpg',
  '../img/Hvannadalshnukur-ice-cave-8.jpg',
  '../img/Hvannadalshnukur-ice-cave-9.jpg',
  '../img/diamond-beach-iceland-1.jpg',
  '../img/diamond-beach-iceland-2.jpg',
  '../img/dyrholaey-viewpoint-vik-iceland-1.jpg',
  '../img/dyrholaey-viewpoint-vik-iceland-2.jpg',
  '../img/dyrholaey-viewpoint-vik-iceland-3.jpg',
  '../img/dyrholaey-viewpoint-vik-iceland-4.jpg',
  '../img/svartifoss-waterfall-iceland-1.jpg',
  '../img/svartifoss-waterfall-iceland-2.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-1.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-2.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-3.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-4.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-5.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-6.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-7.jpg',
  '../img/svinasfellsjokull-glaciar-iceland-8.jpg',
  '../img/seljalandsfoss-waterfall-iceland-1.jpg',
  '../img/seljalandsfoss-waterfall-iceland-2.jpg',
  '../img/seljalandsfoss-waterfall-iceland-3.jpg',
  '../img/seljalandsfoss-waterfall-iceland-4.jpg',
  '../img/seljalandsfoss-waterfall-iceland-5.jpg',
  '../img/skogafoss-waterfall-iceland.jpg',
  '../img/stokksnes-mountain-hofn-iceland-1.jpg',
  '../img/stokksnes-mountain-hofn-iceland-2.jpg',
  '../img/stokksnes-mountain-hofn-iceland-3.jpg',
  '../img/stokksnes-mountain-hofn-iceland-4.jpg',
  '../img/lomagnupur-mountain-iceland-1.jpg',
  '../img/lomagnupur-mountain-iceland-2.jpg',
  '../img/jokulsarlon-glaciar-lagoon-iceland-1.jpg',
  '../img/jokulsarlon-glaciar-lagoon-iceland-2.jpg',
  '../img/jokulsarlon-glaciar-lagoon-iceland-3.jpg',
  '../img/gullfoss-waterfall-iceland.jpg',
  '../img/fjallsarlon-glaciar-lagoon-iceland-1.jpg',
  '../img/fjallsarlon-glaciar-lagoon-iceland-2.jpg',
  '../img/fjallsarlon-glaciar-lagoon-iceland-3.jpg',
  '../img/abandoned-aircraft-iceland-1.jpg',
  '../img/abandoned-aircraft-iceland-2.jpg'
  
]
const images4=[
  '../img/SwitzerlandCover.jpg',
  '../img/zermatt-switzerland-interrail-1.jpg',
  '../img/zermatt-switzerland-interrail-2.jpg',
  '../img/zermatt-switzerland-interrail-3.jpg',
  '../img/zermatt-switzerland-interrail-4.jpg',
  '../img/zermatt-switzerland-interrail-5.jpg',
  '../img/zermatt-switzerland-interrail-6.jpg',
  '../img/zermatt-switzerland-interrail-7.jpg',
  '../img/zermatt-switzerland-interrail-8.jpg',
  '../img/zermatt-switzerland-interrail-9.jpg',
  '../img/zermatt-switzerland-interrail-10.jpg',
  '../img/zermatt-switzerland-interrail-11.jpg',
  '../img/zermatt-switzerland-interrail-12.jpg',
  '../img/zermatt-switzerland-interrail-13.jpg',
  '../img/zermatt-switzerland-interrail-14.jpg',
  '../img/zermatt-switzerland-interrail-15.jpg',
  '../img/zermatt-switzerland-interrail-16.jpg'
]
const images5=[
  '../img/ItalyCover.jpg',
  '../img/milan-italy-interrail-1.jpg',
  '../img/camogli-genoa-italy-interrail-1.jpg',
  '../img/camogli-genoa-italy-interrail-2.jpg',
  '../img/camogli-genoa-italy-interrail-3.jpg',
  '../img/camogli-genoa-italy-interrail-4.jpg',
  '../img/camogli-genoa-italy-interrail-5.jpg',
  '../img/camogli-genoa-italy-interrail-6.jpg',
  '../img/camogli-genoa-italy-interrail-7.jpg',
  '../img/camogli-genoa-italy-interrail-8.jpg',
  '../img/camogli-genoa-italy-interrail-9.jpg',
  '../img/camogli-genoa-italy-interrail-10.jpg',
  '../img/camogli-genoa-italy-interrail-11.jpg',
  '../img/genoa-italy-interrail-1.jpg',
  '../img/genoa-italy-interrail-2.jpg'


]
const images6=[
  '../img/IndonesiaCover.jpg',
  '../img/underwater-statue-gili-t-island-bali-indonesia-1.jpg',
  '../img/turtle-nusa-lembongan-bali-indonesia-1.jpg',
  '../img/tiu-kelep-waterfall-lombok-bali-indonesia-1.jpg',
  '../img/tiu-kelep-waterfall-lombok-bali-indonesia-2.jpg',
  '../img/tegenungan-waterfall-ubud-bali-indonesia-1.jpg',
  '../img/tegenungan-waterfall-ubud-bali-indonesia-2.jpg',
  '../img/star-lombok-bali-indonesia-1.jpg',
  '../img/padar-island-flores-komodo-indonesia-1.jpg',
  '../img/padar-island-flores-komodo-indonesia-2.jpg',
  '../img/padar-island-flores-komodo-indonesia-3.jpg',
  '../img/padar-island-flores-komodo-indonesia-4.jpg',
  '../img/mount-bromo-sunrise-hike-java-indonesia-1.jpg',
  '../img/mount-bromo-sunrise-hike-java-indonesia-2.jpg',
  '../img/mount-bromo-sunrise-hike-java-indonesia-3.jpg',
  '../img/mount-bromo-sunrise-hike-java-indonesia-4.jpg',
  '../img/mount-agung-bali-indonesia-1.jpg',
  '../img/manta-ray-bali-indonesia-1.jpg',
  '../img/lombok-bali-indonesia-1.jpg',
  '../img/kelingking-beach-nusa-penida-bali-indonesia-1.jpg',
  '../img/kelingking-beach-nusa-penida-bali-indonesia-2.jpg',
  '../img/kelingking-beach-nusa-penida-bali-indonesia-3.jpg',
  '../img/kelingking-beach-nusa-penida-bali-indonesia-4.jpg',
  '../img/kelingking-beach-nusa-penida-bali-indonesia-5.jpg',
  '../img/kanawa-island-flores-komodo-indonesia-1.jpg',
  '../img/kanawa-island-flores-komodo-indonesia-2.jpg',
  '../img/kanawa-island-flores-komodo-indonesia-3.jpg',
  '../img/kanawa-island-flores-komodo-indonesia-4.jpg',
  '../img/gili-t-island-bali-indonesia-1.jpg',
  '../img/flores-komodo-island-indonesia-1.jpg',
  '../img/devils-tear-nusa-lembongan-bali-indonesia-1.jpg',
  '../img/devils-tear-nusa-lembongan-bali-indonesia-2.jpg'
]
const images7=[
  '../img/SpainCover.jpg',
  '../img/la-sagrada-familia-barcelona-spain-interrail-1.jpg',
  '../img/la-sagrada-familia-barcelona-spain-interrail-2.jpg',
  '../img/hospital-del-la-santa-barcelona-spain-interrail-1.jpg',
  '../img/cathedral-of-barcelona-spain-interrail-1.jpg',
  '../img/arc-de-triomf-barcelona-spain-interrail-1.jpg',
  '../img/arc-de-triomf-barcelona-spain-interrail-2.jpg'
]
const images8=[
  '../img/FranceCover.jpg',
  '../img/st-nicholas-russian-orthodox-cathedral-nice-france-interrail-1.jpg',
  '../img/nice-france-interrail-1.jpg',
  '../img/nice-france-interrail-2.jpg',
  '../img/nice-france-interrail-3.jpg'
]
const images9=[
  '../img/IrelandCover.jpg',
  '../img/lough-bray-kippure-wicklow-mountains-5.jpg',
  '../img/lough-bray-kippure-wicklow-mountains-4.jpg',
  '../img/lough-bray-kippure-wicklow-mountains-3.jpg',
  '../img/lough-bray-kippure-wicklow-mountains-2.jpg',
  '../img/lough-bray-kippure-wicklow-mountains-1.jpg',
  '../img/wicklow-mountains-ireland-1.jpg',
  '../img/wicklow-mountains-ireland-2.jpg',
  '../img/wicklow-mountains-ireland-3.jpg',
  '../img/wicklow-mountains-ireland-4.jpg',
  '../img/wicklow-mountains-ireland-5.jpg',
  '../img/wicklow-mountains-ireland-6.jpg',
  '../img/wicklow-mountains-ireland-7.jpg',
  '../img/russborough-house-wicklow-ireland-1.jpg',
  '../img/russborough-house-wicklow-ireland-2.jpg',
  '../img/russborough-house-wicklow-ireland-3.jpg',
  '../img/russborough-house-wicklow-ireland-4.jpg',
  '../img/russborough-house-wicklow-ireland-5.jpg',
  '../img/rock-of-cashel-ireland-1.jpg',
  '../img/rock-of-cashel-ireland-2.jpg',
  '../img/nohoval-cove-kinsale-cork-ireland-1.jpg',
  '../img/nohoval-cove-kinsale-cork-ireland-2.jpg',
  '../img/dublin-ireland-1.jpg',
  '../img/dublin-ireland-2.jpg',
  '../img/dublin-ireland-3.jpg',
  '../img/dublin-ireland-4.jpg',
  '../img/baltinglass-wicklow-ireland.jpg'
]
const images10=[
  '../img/HomeCover.jpg',
  '../img/blessington-lake-wicklow-ireland-1.jpg',
  '../img/blessington-lake-wicklow-ireland-2.jpg',
  '../img/blessington-lake-wicklow-ireland-3.jpg',
  '../img/blessington-lake-wicklow-ireland-4.jpg',
  '../img/blessington-lake-wicklow-ireland-5.jpg',
  '../img/blessington-lake-wicklow-ireland-6.jpg',
  '../img/blessington-lake-wicklow-ireland-7.jpg',
  '../img/blessington-lake-wicklow-ireland-8.jpg',
  '../img/blessington-lake-wicklow-ireland-9.jpg',
  '../img/blessington-lake-wicklow-ireland-10.jpg',
  '../img/blessington-roundabout-wicklow-ireland-1.jpg',
  '../img/blessington-roundabout-wicklow-ireland-2.jpg',
  '../img/blessington-gaa-pitch-ireland-1.jpg',
  '../img/blessington-town-wicklow-ireland-1.jpg',
  '../img/blessington-town-wicklow-ireland-2.jpg',
  '../img/blessington-town-wicklow-ireland-3.jpg',
  '../img/blessington-town-wicklow-ireland-4.jpg',
  '../img/blessington-town-wicklow-ireland-5.jpg'

]
const images11=[
  '../img/NQCover.jpg',
  '../img/nutri-quick-product-photography-1.jpg',
  '../img/nutri-quick-product-photography-2.jpg',
  '../img/nutri-quick-product-photography-3.jpg',
  '../img/nutri-quick-product-photography-4.jpg',
  '../img/nutri-quick-product-photography-5.jpg',
  '../img/nutri-quick-product-photography-6.jpg',
  '../img/nutri-quick-product-photography-7.jpg',
  '../img/nutri-quick-product-photography-8.jpg',
  '../img/nutri-quick-product-photography-9.jpg',
  '../img/nutri-quick-product-photography-10.jpg',
  '../img/nutri-quick-product-photography-11.jpg',
  '../img/nutri-quick-product-photography-12.jpg',
  '../img/nutri-quick-product-photography-13.jpg',
  '../img/nutri-quick-product-photography-14.jpg',
  '../img/nutri-quick-product-photography-15.jpg',
  '../img/nutri-quick-product-photography-16.jpg'
]
const images12=[
  '../img/DWCover.jpg',
  '../img/devo-watches-watch-photography-1.jpg',
  '../img/devo-watches-watch-photography-2.jpg',
  '../img/devo-watches-watch-photography-3.jpg',
  '../img/devo-watches-watch-photography-4.jpg',
  '../img/devo-watches-watch-photography-5.jpg',
  '../img/devo-watches-watch-photography-6.jpg',
  '../img/devo-watches-watch-photography-7.jpg',
  '../img/devo-watches-watch-photography-8.jpg',
  '../img/devo-watches-watch-photography-9.jpg',
  '../img/devo-watches-watch-photography-10.jpg'

]
const images=[
  images1,images2,images3,images4,images5,images6,images7,images8,images9,images10,images11,images12
]

function Photos(props) {
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  
  return (
    // Render a Thumbnail component

    <div className="videos">
      <FsLightbox
        toggler={ toggler }
        sources={ images[productIndex] }
        key={ productIndex }
      />
      <Thumbnail
        link="../img/PhilippinesCover.jpg"
        image="../img/PhilippinesCover.jpg"
        title="Philippines"
        category="Coron, Siargao and Cebu islands"
        onClick={ () => {setProductIndex(0); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/ThailandCover.jpg"
        image="../img/ThailandCover.jpg"
        title="Thailand"
        category="Bangkok and Chiang Mai"
        onClick={ () => {setProductIndex(1); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/IcelandCover.jpg"
        image="../img/IcelandCover.jpg"
        title="Iceland"
        category="One week in the land of ice and fire"
        onClick={ () => {setProductIndex(2); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/SwitzerlandCover.jpg"
        image="../img/SwitzerlandCover.jpg"
        title="Switzerland"
        category="Some shots from Zermatt"
        onClick={ () => {setProductIndex(3); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/ItalyCover.jpg"
        image="../img/ItalyCover.jpg"
        title="Italy"
        category="Some shots from Genoa"
        onClick={ () => {setProductIndex(4); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/IndonesiaCover.jpg"
        image="../img/IndonesiaCover.jpg"
        title="Indonesia"
        category="Bali, Lombok, Java, Gili Islands and Komodo"
        onClick={ () => {setProductIndex(5); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/SpainCover.jpg"
        image="../img/SpainCover.jpg"
        title="Spain"
        category="Some shots from Barcelona"
        onClick={ () => {setProductIndex(6); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/FranceCover.jpg"
        image="../img/FranceCover.jpg"
        title="France"
        category="Nice and Marseille"
        onClick={ () => {setProductIndex(7); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/IrelandCover.jpg"
        image="../img/IrelandCover.jpg"
        title="Ireland"
        category="Shots from around Ireland"
        onClick={ () => {setProductIndex(8); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/HomeCover.jpg"
        image="../img/HomeCover.jpg"
        title="Home"
        category="Pictures of Blessington in Wicklow"
        onClick={ () => {setProductIndex(9); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/NQCover.jpg"
        image="../img/NQCover.jpg"
        title="NutriQuick"
        category="Food & Event Photography"
        onClick={ () => {setProductIndex(10); setToggler(!toggler);}}
      />

      <Thumbnail
        link="../img/DWCover.jpg"
        image="../img/DWCover.jpg"
        title="DevoWatches"
        category="Watch Photography"
        onClick={ () => {setProductIndex(11); setToggler(!toggler);}}
      />
    </div>
  );
}

export default Photos;
