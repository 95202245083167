import React from "react";
import Thumbnail from "./thumbnail.js";
import "../photo.css";
import { NavLink } from "react-router-dom";

function Blog(props) {
  return (
    // Render a Thumbnail component

    <div className="videos">
      <NavLink
              exact={true}
              to="/photography/countries"
              className="item"
              activeClassName="selected"
          >
          <Thumbnail
            image="../img/TopTenCountries.jpg"
           title="Top 10 Countries"
            category="Here is my list of the top 10 countries to travel to around the world"
          />
            </NavLink>
            <NavLink
              exact={true}
              to="/photography/places"
              className="item"
              activeClassName="selected"
          >
          <Thumbnail
            image="../img/MustSeeCover.jpg"
           title="Top 10 Must See Places"
            category="Here is my list of the top 10 must see places around the world"
          />
            </NavLink>
            <NavLink
              exact={true}
              to="/photography/philippinesguide"
              className="item"
              activeClassName="selected"
          >
          <Thumbnail
            image="../img/PhilippinesGuide.jpg"
           title="Two Week Philippines Itinerary"
            category="Here is the itinerary of my two week trip to the Philippines"
          />
          </NavLink>
            <NavLink
              exact={true}
              to="/photography/icelandguide"
              className="item"
              activeClassName="selected"
          >
          <Thumbnail
            image="../img/icelandguide.jpg"
           title="6 Day Iceland Itinerary"
            category="Here is the itinerary of my six day trip to Iceland"
          />
            </NavLink>
    </div>
  );
}

export default Blog;
