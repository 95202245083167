import React from "react";
import { NavLink } from "react-router-dom";

//Functional Component 
const MainPage = () => {
  return (
    <div className="container1">
        <div className="split left">
          <h1>Software<br></br>Developer</h1>
          <NavLink to="/technology" className="buttonLink">Discover</NavLink>
        </div>
        <div className="split right">
          <h1>Media<br></br>Creator</h1>
          <NavLink to="/photography" className="buttonLink">Explore</NavLink>
        </div>
    </div>
    
  );
};

export default MainPage;