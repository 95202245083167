import React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import MainPage from "../pages/index";
import TechnologyPage from "../pages/technology";
import Videos from "../components/videos";
import Photos from "../components/photos";
import About from "../components/about";
import Blog from "../components/blog";
import Countries from "../components/countries";
import Cities from "../components/cities";
import Places from "../components/places";
import PhilippinesGuide from "../components/philippinesguide";
import IcelandGuide from "../components/icelandguide";

import "../photo.css";
import { NavLink } from "react-router-dom";

const PhotographyPage = () => {
  return (
    <BrowserRouter forceRefresh={true}>
      <div className="Photo">
        <div className="logo">
          <NavLink to="/">
            <img
              src="../img/Dave.jpg"
              alt="David Boothman"
            />
            <span>David Boothman</span>
            
          </NavLink>
        </div>

        <div className="nav">
          <div className="nav-sub">
            <NavLink
              exact={true}
              to="/photography"
              className="item"
              activeClassName="selected"
            >
              Videos
            </NavLink>
            <NavLink to="/photography/photos" className="item" activeClassName="selected">
              Photos
            </NavLink>
            <NavLink to="/photography/blog" className="item" activeClassName="selected">
              Travel Guide
            </NavLink>
            <NavLink to="/photography/about" className="item" activeClassName="selected">
              About
            </NavLink>
          </div>
        </div>
        <Route exact={true} path="/" component={MainPage} />
        <Route exact={true} path="/technology" component={TechnologyPage} />
        <Route exact={true} path="/photography" component={Videos} />
        <Route exact={true} path="/photography/videos" component={Videos} />
        <Route exact={true} path="/photography/photos" component={Photos} />
        <Route exact={true} path="/photography/blog" component={Blog} />
        <Route exact={true} path="/photography/countries" component={Countries} />
        <Route exact={true} path="/photography/cities" component={Cities} />
        <Route exact={true} path="/photography/places" component={Places} />
        <Route exact={true} path="/photography/philippinesguide" component={PhilippinesGuide} />
        <Route exact={true} path="/photography/icelandguide" component={IcelandGuide} />
        <Route path="/photography/about" activeClassName="is-active" component={About} />

        <footer>
          <a href="https://www.youtube.com/channel/UC1ECYHHTvfcOk0dbT0sSKdw/" target="blank">
            YouTube
          </a>
          <a href="https://www.instagram.com/david_boothman/" target="blank">
            Instagram
          </a>
          <a
            href="https://www.linkedin.com/in/david-boothman-937ab3111/"
            target="blank"
          >
            LinkedIn
          </a>
          <a href="mailto:davidboothman@gmail.com" target="blank">
            Email
          </a>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default PhotographyPage;