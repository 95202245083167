import React from "react";
import "../about.css";
function PhilippinesGuide(props) {
  return (
    <div className="about">
        <div className="w3-col">
            <img src="../img/PhilippinesGuide.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%' ,justifyContent: 'center'}} />
          </div>
          <div className="w3-container w3-padding-64" id="contact">
          <h5 className="w3-center">Two Week Philippines Itinerary</h5><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>Back in October 2019, myself and two friends travelled to the Philippines for a two-week adventure to see what these amazing
          islands have to offer. In the below article I have laid out our two-week itinerary as well as provided some advice as to what I liked and what I would have done differently. In terms of what
          I would have done differently, I probably would have gone for a 2-3 day boat tour from El Nido to Coron which we hadn't heard about until we arrived in Coron. This would have allowed us to also
          see El Nido which is one of the most popular destinations in the Philippines and as a result, we would have spent less time on Busuanga Island where there is not as much to do or see. I also
          would probably have not gone to Cebu if it wasn't for the Whale Sharks and so unless you really want to see them I would choose another island to visit in the Philippines.</p>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-2.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 1: Arrive in Coron</h5><br />
            <p className="w3-large">To get to Coron you will have to first fly into Manila before getting a connecting flight that will bring you to the island of Busuanga. The flight is only an hour long and you can get some really nice views of Coron (pictured) as you begin to land. You will then land in the tiny Busuanga airport where you will find taxis outside with each going to a different town/hostel on the island. Hop on the taxi going to your hostel and 30 minutes later you'll be in Coron town.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Once you get to your hostel(we stayed in Hop Hostel which has great food and unbelievable views of the bay) you will have most of the day left to explore the town of Coron. We decided to rent bikes right across the road from our hostel and set off to check out the town and beaches nearby. We also went to the docks to organise a boat trip for the next day and managed to get a decent price. That night our hostel had organised a trip to Maquinit Hot Spring which was really relaxing and we managed to recruit some people for our boat tour.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 2: Private Boat Tour</h4><br />
            <p className="w3-large">Your boat captain will agree a time to meet you at the dock which will usually be first thing in the morning. From here they will bring you to the local market where you can pick up essentials for the day like food, water, ice and most importantly beer! Then you'll bring everything back to the boat and from there you'll be ready to set off. The advantage of a private tour is that you'll get to beat all of the organised tour boats to get to popular locations first where there will be no crowds and you can enjoy these places in peace.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The private boats will usually have different tours you can choose from but we went for the most common tour which was Kayangan Lake, Baracuda Lake, Twin Lagoon, Banul beach, Coral garden and a sunken ship. This is a good option if it's your first time in Coron and you'd like to see the popular destinations while avoiding the crowds. The lakes have some of the clearest water you'll ever see and swimming through the underwater cave in twin lagoon is a great experience.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-3.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-4.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 3: Rent Bikes and Explore</h5><br />
            <p className="w3-large">On the third day in Coron, you could do another boat tour or you could rent bikes and explore the island of Busuanga. We opted for organising a tour the day after and for this day renting bikes from the same place that we had previously rented bikes from and it cost us around 400 pesos or 7 euros each for the day. When renting a bike you'll be asked to fill out a form with all of your details and provide some form of ID as a deposit that will be returned once you bring back the bike which is standard procedure in the Philippines.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">We searched for places to explore on the island and saw that Marcilla beach was about 50 minutes away from Coron town and looked amazing. It wasn't until about halfway there that we realised the roads were way too bad for our bikes to drive on and we made the decision to turn back. Instead, we made our way West to Concepcion Falls(pictured) to chill out in the water. Afterwards, we got back to Coron just in time for sunset and made our way to the top of Mount Tapyas for an unbelievable view of the bay and sunset.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 4: Another Private Boat Tour</h4><br />
            <p className="w3-large">On your last day in Coron, it is definitely worth doing another boat tour. We had organised the boat tour with the same boat and crew as the previous tour and this time we opted for an exotic island tour. The way the tours worked was the further the locations were away from Coron town, the more money they cost and so while this was not the most expensive tour, it was more expensive as the previous one. The island tour is great if you just want to relax and chill out on a beach and is a lot more laid back than the previous tour.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The tour we picked had three stops which were Malcapuya, Ditaytayan and Banana islands. After a long journey of over an hour by boat, we landed on one of the best islands near Coron, Malcapuya. We had the white sand beach and crystal clear water to ourselves and a few locals which made the experience even better. Next was Ditaytayan island (pictured) which has unique look with a sandbar sticking out into the ocean. Lastly, we landed on Banana island where we were the only people on the island and got to chill out in some small huts and sunbathe(burn).</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-5.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-6.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 5: Fly To Siargao</h5><br />
            <p className="w3-large">On the fifth day, you will check out of your hostel and hop in a taxi which will take you back to the airport, this can be pre-arranged with your hostel. There are no direct flights from Coron to Siargao so you will have to first fly to Cebu and then catch a connecting flight to Siargao island. You could just fly to Cebu and stay there and explore the island if you would prefer but we decided to head to Siargao first as we would have to fly back to Cebu anyway to catch a flight to Manila to head home.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">After travelling for most of the day we arrived in Siargao in the afternoon and caught a taxi to Mad Monkey Hostel in General Luna. This hostel is a great place to meet other people and has a huge party culture if that is what you're looking for. We arrived just in time for the end of sunset at a small beach near Cloud 9 (pictured). That night the hostel had a games night which was followed by a trip to the local 'Jungle' night club where locals and tourist go and party until late in the night. </p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 6: Magpupungko Rock Pools</h4><br />
            <p className="w3-large">On your first full day in Siargao, you should take some time to explore the island itself. Some of the people we met the night before had asked us to join them and make the trip to the Magpupungko rock pools(pictured). The rock pools are about an hour away from General Luna so it's a good idea to either rent bikes from your hostel like we did or negotiate a price with the local tricycle drivers to be your driver for the day. The roads in Siargao are very good and new so most places are accessible by bike.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The journey to the rock pools is a very scenic one and you will see lots of palm trees along the way. There are a few places that you can stop and admire the scenery with Coconut Trees View Deck overlooking fields of palm trees and nearby the bent palm tree swing is worth checking out. Once you get to the rock pools you can walk out from the beach and find small pools to sit in and relax or you can head over to the larger pool with clear blue water and nice boulders to jump off from.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-7.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-8.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 7: Island Hopping Tour</h5><br />
            <p className="w3-large">Once or twice a week the hostel will offer an island hopping tour which you can sign up for at the front desk. Alternatively, you can head down to the dock in General Luna and organise a trip yourself. The typical Siargao island hopping tour consists of three islands which are Casulian, Naked and Daku Islands. We decided to go on the tour as most of the people we met were also going and we thought it would be fun. Food and drink were all provided on the trip and beer could also be bought on some of the islands.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">It wasn't long into the trip that the tour became more of a booze cruise than an island hopping tour but that suited us perfectly. We arrived on Casulian island and began a short hike to the highest point giving us a great view of the area before sitting down for a huge meal. Next was Naked Island(pictured) which is really unique because the island is literally naked with nothing on it but sand. Lastly, we landed on Daku island for some volleyball and more drinking. Overall the tour was really fun!</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 8: Chill Day</h4><br />
            <p className="w3-large">We didn't plan to just have a chill day on our third day in Siargao but due to some severe hangovers and even more severe sunburn, we decided that a day relaxing in the hostel and General Luna was needed. There are some nice little bars and restaurants in General Luna and we managed to catch the Rugby World Cup final in Barrel Sports Bar which had a great atmosphere. You can also go to the restaurants in some of the 5-star resorts where the food is really nice but still affordable.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Originally we had planned to head to Sohoton Cove for the day instead of just chilling out. This place looks incredible, especially if you google some drone shots of the place, and it is a place where you can find caves, lagoons and cliff jumps of all kinds. It is also home to jellyfish sanctuaries where you can find thousands of little orange jellyfish in the water. To get to Sohoton Cove you can organise a private tour at the dock or you can also find island hopping tours which include the cove as a stop.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-12.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-9.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 9: Fly to Cebu</h5><br />
            <p className="w3-large">The next day you will pack your bags and hop in a taxi back towards Siargao airport where you will catch your flight back to Cebu. Once you touch down in Cebu you should get some food and then get a taxi to the Cebu bus terminal. The bus terminal can be a bit hectic so just ask for the bus to Moalboal and you will be pointed in the direction of the queue where you will pay for your ticket. The bus takes over two hours to get to Moalboal but it does have air conditioning so try and get as comfortable as you can.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Once you get to Moalboal you can then either walk or get a taxi to your hostel depending on how far away it is. We stayed in MoHo hostel which was pretty comfortable and had a happy half-hour every night where all drinks were free so that was right up our alley. Once we were settled in we went down towards the shoreline for some food and an unbelievable sunset. We also located the spot where we should swim out the next day to see the incredible sardine run (pictured) which is just out from Chilli Bar.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 10: Sardine Run & Canyoneering</h4><br />
            <p className="w3-large">The next morning you should take a walk back down to the shoreline for some breakfast in one of the cafes/restaurants. We picked The French Coffee Shop which had a great selection of breakfast and smoothies. After breakfast, you can head to any of the dive shops and rent snorkelling equipment for the sardine run. Next, you should head to the beach near Chilli Bar and pick a spot to place all of your stuff. If you look out in the water you'll see people swimming around about 20-30 metres from the beach and that is where you'll find the unbelievable sardine run.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">After swimming around and witnessing the sardine run you can then rent a bike and drive yourself or organise a tour to go canyoneering in the afternoon. If you drive yourself then you can stop off at any of the waterfall locations down the coast such as Kawasan Falls, Inambakan Falls or Mantayupan Falls and you will find some locals to be your guide while you make your way through canyons, swim in rivers and jump into pools of the bluest water you'll ever see.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-11.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-1.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">Day 11: Oslob Whale Sharks</h5><br />
            <p className="w3-large">You can only see the whale sharks first thing in the morning so it is a good idea to make your way to Oslob the night before as we did. There are tours which go from Moalboal also but they require a 2/3 am start. The experience starts with a presentation about what to do and what not to do while swimming with them. Then you'll be led to your boat which will take you about 100 yards out from shore. You'll then be told to hop in the water and smaller boats will guide 5-10 sharks towards you with food as you snorkel.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The whale sharks in Oslob are one of the main reasons that people come to Cebu in the first place. The whale shark 'experience' is seen as a bit controversial as some argue it is harming these amazing creatures while others say that it helps to keep the population alive and gives them a constant source of food. Personally, I found it to be a surreal and breathtaking being so close to these giant whale sharks and I do not regret doing it, especially as it had been on my bucket list for so long.</p>  
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">Day 12: Sumilon Island & Cebu City</h4><br />
            <p className="w3-large">After seeing the whale sharks and exploring the small town of Oslob the only other things to do are to either check out some waterfalls nearby or to take a boat trip to Sumilon Island just off the coast of Oslob. If waterfalls are what you're after then Tumalog Falls is about 40 minutes away from Oslob town. If you want to see another amazing beach then you should get a boat over to Sumilon Island for a white sand beach and clear blue water where you can chill out for the morning/afternoon.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">As you will probably have an early flight home the next day it is a good idea to make your way back to Cebu City at some point. We organised a taxi with our hostel that would drive us a few hours north up to Cebu City rather than get on the bus again. We chose to stay in Mad Monkey hostel in Cebu after our experience in Siargao and it was a good choice as they had a rooftop bar with a pool and some great food too. The next morning we got a taxi to the airport and we were on our way home.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/philippines-guide-coron-siargao-cebu-10.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
      </div>
      
  );
}

export default PhilippinesGuide;
