/*App.js*/
import React, { Component } from "react";
import "./App.css";
import MainPage from "./pages/index";
import TechnologyPage from "./pages/technology";
import PhotographyPage from "./pages/photography";
//Import all needed Component for this tutorial
import {
  BrowserRouter,
  Route
} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route exact={true} path="/" component={MainPage} />
        <Route exact={true} path="/technology" component={TechnologyPage} />
        <Route exact={true} path="/photography" component={PhotographyPage} />
        <Route exact={true} path="/photography/videos" component={PhotographyPage} />
        <Route exact={true} path="/photography/photos" component={PhotographyPage} />
        <Route exact={true} path="/photography/blog" component={PhotographyPage} />
        <Route exact={true} path="/photography/countries" component={PhotographyPage} />
        <Route exact={true} path="/photography/cities" component={PhotographyPage} />
        <Route exact={true} path="/photography/places" component={PhotographyPage} />
        <Route exact={true} path="/photography/philippinesguide" component={PhotographyPage} />
        <Route exact={true} path="/photography/icelandguide" component={PhotographyPage} />
        <Route path="/photography/about" activeClassName="is-active" component={PhotographyPage} />
      </BrowserRouter>  
    );
  }
}

export default App;