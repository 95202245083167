import React from "react";
import Header from "../components/tech/Header";
import Footer from "../components/tech/Footer";
import Content from "../components/tech/Content";
import { HashRouter } from "react-router-dom";
//Functional Component 
const TechnologyPage = () => {
  return (
    <React.Fragment>
      <HashRouter>
        <Header/>
        <Content />
        <Footer />
      </HashRouter>
    </React.Fragment>
    
  );
};

export default TechnologyPage;