import React from "react";
import "../about.css";
function Places(props) {
  return (
    <div className="about">
        <div className="w3-col">
            <img src="../img/MustSeeCover.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%' ,justifyContent: 'center'}} />
          </div>
          <div className="w3-container w3-padding-64" id="contact">
          <h5 className="w3-center">Top 10 Must See Places</h5><br />
          <p className="w3-large w3-text-grey" style={{textAlign: 'center'}}>Here I have put together a list of the top ten must-see places in the world that I have been to. The list is a mix of both popular and lesser-known destinations and can hopefully give you a few more places to add to your travel bucket list. Some honourable mentions which didn't make the list include Tiu Kelep waterfall in Lombok, Dyrholaey and Stokksnes in Iceland and Lake Tahoe and Yosemite in California.</p>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/GiliT.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">10. Gili Trawangan, Indonesia</h5><br />
            <p className="w3-large">Kicking off the list at number ten is the island of Gili Trawangan or Gili T in Indonesia. The Gili islands are a popular getaway destination with Gili T being the biggest and most popular island. The island has plenty to offer from white sand beaches where horses can be seen trotting along now and again to a great nightlife scene that will have you partying until the early hours of the morning. Besides the beaches and the nightlife, you also have the ocean around the island which offers some incredible marine life and diving experiences where you can see a wide range of creatures including sea turtles! Boat tours are widely offered on the island and you can select from several different locations with the most common places being the underwater statues(pictured), a shipwreck and snorkelling with turtles.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Gili T can be found just off the coast of Lombok island which is a neighbour of the popular travel destination of Bali. To get here you can either take a fast boat from Padang Bai or a fast boat from Amed with each taking no more than 1/2 hours. Alternatively, you can get to Gili T from Lombok by getting a boat from Teluk Nare Harbour in the north or from Senggigi Beach in the west.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">9. Svinafellsjøkull, Iceland</h4><br />
            <p className="w3-large">Next up we have the Svinafellsjøkull glacier in the south of Iceland. The glacier (pictured) is one of the most breathtaking things I've ever seen and is definitely one of the top things you can do in Iceland. By going to see this place it will probably be as close as you'll get to a glacier without doing one of the ice cave tours or glacier hike. You get to see the patterns of the ice as it flows down from the top of the mountain to the lagoon at the bottom. The view is unbelievable from the ground and it is even better from the sky as you can really appreciate the size and scale of the glacier and the patterns created by the ice are amazing from above. If you sit and listen for a while you will hear the occasional cracking of ice coming from the glacier.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Svinafellsjøkull glacier is part of the larger Vatnajøkull glacier is located in the national park in the south-east of Iceland. To get to the glacier, you would have to take an almost 5-hour car journey from Reykjavik along to 1 road to get there. It is a much better idea to work your way over to the glacier over a couple of days while stopping at several different spots along the way. Near to the glacier, you'll find a small dirt road which will lead you to the car park. From the car park, you'll see a path that will lead you along the left side of the glacier.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Glaciar.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Zermatt.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">8. Zermatt, Switzerland</h5><br />
            <p className="w3-large">At number eight on the list, we have the small town of Zermatt located in the Swiss-Italian Alps in Switzerland. This place is made famous by the Matterhorn mountain that stands tall over the valley in which the town lays. The views around the town are unbelievable and during the summer hiking is a popular activity while during winter, skiing is the main attraction. One must-do thing while you're in Zermatt is the hop on the Gornergrat, the highest open-air railway in Europe, and ride the train until the last stop at the top where you'll be at an altitude of 3,089m and have amazing views of the Matterhorn. Zermatt also has the Rothorn mountain, Matterhorn Glacier Paradise and cable cars to drop you off anywhere on the mountains so there is plenty to do here.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">One cool fact about Zermatt is that there are no cars allowed in the town at all which means that the only way to get there is by train. If you are driving then you can park your car in Tasch which is just one stop away on the train from Zermatt itself. If you're coming by train then you can stop off at Brig or Visp where you can hop on the cog railway that will take you to Zermatt.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">7. Lake Bled, Slovenia</h4><br />
            <p className="w3-large">Up next we have the popular interrail destination of Lake Bled in Slovenia. This is one of the most picturesque places in Slovenia and maybe even Europe. There are not many places in the world where you will find views like these with a church sitting in the middle of a bluish-green lake surrounded by mountains with a castle towering over the lake itself. Bled is also known for its wide variety of activities that it has to offer including plenty of amazing walks and hikes, tobogganing down the surrounding mountains and boating and kayaking on the lake itself to name a few. I would recommend renting motorbikes and exploring along the roads that wrap around the lake for even more unique views of the area.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Located in the northwestern part of Slovenia, Lake Bled can be a bit awkward to get to. The easiest way is by bus from the capital city of Ljubljana with the journey taking about 70 minutes in total. If you're coming by train then you can stop at the Lesce train station which is about 5km from Lake Bled and you can catch a bus or a taxi that will drop you right to the lake. If you're driving from Ljubljana, the journey takes about 40 minutes and you can find parking places easily around the lake.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Slovenia.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Halstatt.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">6. Halstatt, Austria</h5><br />
            <p className="w3-large">At number six we have another small picturesque place in central Europe known as Hallstatt in Austria. This place is very similar to Lake Bled in that it is a small postcard-worthy town with a lake surrounded by mountains. The views of Halstatt, whether it's from on the ferry which brings you across the lake into the town itself or whether it's from the view deck which overlooks the town and lake from the surrounding mountains, will leave you in awe of unbelievable the scenery is. There are always plenty of activities to do in the town and the surrounding area such as the Dachstein Ice Cave or the Halstatt Salt Mine or you can even rent a boat and row around the lake. There are also some incredible viewpoints on top of the mountains in the area with the Five Fingers Viewpoint being the main attraction.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The easiest way to get to Hallstatt is by car with the journey taking just over an hour from Salzburg while it takes two and a half hours to drive from Munich. Alternatively, you can hop on the train which takes a bit longer but only requires one change over of trains at the Attnang-Puchheim station where you will transfer on to the local train which will take you to Halstatt. The train journey from Salzburg takes around about two and a half hours in total.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">5. Coron Island, Philippines</h4><br />
            <p className="w3-large">Next, we have one of the most scenic islands in the world and one that is a must-visit whenever you visit the Philippines, the island of Coron. This place is known for its clear lakes, amazing beaches and unique underwater diving experiences. Kayangan Lake is known around Asia as one of the clearest lakes there is and the short hike there has some amazing views of the bay nearby. Some of the islands nearby to Coron are also a must-see and you'll find some of the best beaches you'll ever set foot on with Malcapuya and Ditaytayan islands being the highlights. Coron Island has some amazing diving experiences as you'll be able to find a total of 12 Japanese shipwrecks from World War II scattered throughout the waters surrounding the island.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Coron Island is located in the province of Palawan in the west of the Philippines. You can get to the island by first flying to the island of Busuanga and from there catching a taxi to Coron town. Coron Island is then just a short 30-minute boat journey from Coron Town. There is also the option of jumping on a 3/4 day boat tour that will take you from El Nido to Coron while allowing you to stop at the many islands there are along the way.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/kayangan-lake-coron-philippines.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Bromo.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">4. Mount Bromo, Indonesia</h5><br />
            <p className="w3-large">At number four we have one of the most incredible and picturesque volcanos in the world, Mount Bromo. You're not likely to find another volcano as breathtaking as Mount Bromo and this is mostly due to its location in the middle of a huge crater surrounded by several other volcanos. To get the most out of the Mount Bromo adventure, you will have to do the sunrise hike up the nearby King Kong hill where you will get the best views of the whole crater. The thing I love about the sunrise hike is that you start at the base of the mountain in darkness and just climb until you find an ideal spot for sunrise where you'll witness some of the best views of your life. After sunrise, you can then head down into the crater itself where you'll be able to climb and witness the volcano up close.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Mount Bromo is located on the island of Java which is just west of Bali in Indonesia. You can get here from Bali by making you to the west side of the island and getting a short ferry across to Java. From the port in Java, you can then get a bus or taxi to Probolinggo where you can easily find a tour or make your own way to Bromo. Alternatively, you can fly into to Surabaya and make your way to Probolinggo and then Bromo that way.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">3. Vatnajøkull Ice Caves, Iceland</h4><br />
            <p className="w3-large">Making up the number three spot on the list is one of the most mind-blowing experiences you'll ever have anywhere in the world and that is visiting the ice caves of Vatnajøkull national park in Iceland. Vatnajøkull is the second largest ice cap in Europe and has around 30 outlet glaciers which flow down the Icelandic mountains leaving waves of ice in their trail. Water streams make their way through the glaciers forming ice caves. To visit the ice caves you'll have to take a tour with a company such as Local Guide. The tours will normally take you in a specialised super jeep capable of scaling the glaciers and will drop you right outside the ice caves. From there you be guided towards the ice cave and as you climb in and see the blue ice around you it will be like stepping into another world.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The Vatnajøkull ice caves can be found in the south of Iceland. Most tours will begin with the car park of Jøkulsarlon Glacier Lagoon being the meeting point. Jøkulsarlon is located right beside Diamond Beach and is well over four hours drive from Reykjavik so it is best to stay in either Vik or Hof the night before your tour to make things easier for you.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/IceCave.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-64" id="about">
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/NusaPenida.jpg" className="w3-round w3-image w3-opacity-min" alt="Table Setting" style={{width: '100%'}}/>
          </div>
          <div className="w3-col m6 w3-padding-large">
            <h5 className="w3-center">2. Nusa Penida, Indonesia</h5><br />
            <p className="w3-large">In at number two on the list we have the island of Nusa Penida in Indonesia. This island is an absolute gem and is full of scenic cliffs and beaches. It is relatively untouched in most parts which is great for the sense of adventure you get but just keep an eye out for the bad roads which are everywhere. The most famous spot in Nusa Penida is without a doubt Kelingking Beach or T-Rex Bay as it's also known. The cliffs reach out into the ocean in the shape of a dinosaur (hence the name T-Rex Bay) and you can scale down the bamboo stairs of the cliffs to get to one of the nicest beaches in the world at the bottom. Other must-see spots in Nusa Penida include Broken Beach, Atuh Beach, Banah Cliffs, Peguyangan Falls and Seganing Falls.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">Nusa Penida is a small island located just east of Bali and has two neighbours which together make up the Nusa Islands, Nusa Cenigan and Nusa Lembongan. The most common and easiest way to get to the island is via fast boat from Sanur Beach in Bali. It is also easy to get between the Nusa Islands with many boats offering to take you around.</p>
          </div>
        </div>
        <hr />
        <div className="w3-row w3-padding-small" id="menu">
          <div className="w3-col l6">
            <h4 className="w3-center">1. Komodo Islands, Indonesia</h4><br />
            <p className="w3-large">Finally, at number one we have my favourite place in the entire world and one of the few untouched places that we have left, the Komodo Islands in Indonesia. The Komodo islands are made up of three main islands, Komodo Island, Rinca Island and Padar Island. Each of the islands has amazing landscapes with Padar island being the most spectacular and without a doubt one of the most unique islands in the world. The Komodo islands also offer some of the best underwater experiences that you will find anywhere with some incredible marine life to be seen. The islands are most famous for being the natural habitat of the Komodo dragon, the largest lizard on the planet, with over 5,000 dragons scattered around the islands. The Komodo Islands also have one of seven pink beaches in the world so that is well worth a visit.</p>
            <p className="w3-large w3-text-grey w3-hide-medium">The Komodo islands are located in the East Nusa Tenggara province which is the southern most province in Indonesia. To get there you can fly into Labuan Bajo airport which takes about an hour from Bali and from here you can hop on day tours or 2/3 day boat tours to the actual islands. You can also take boat trips from Lombok which last about 4/5 days and stop off at all of the main spots.</p>
          </div>
          <div className="w3-col l6 w3-padding-large">
            <img src="../img/Komodo.jpg" className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width: '100%'}} />
          </div>
        </div>
        <hr />
      </div>
  );
}

export default Places;
